import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`

export const Content = styled.section`
  display: flex;
  flex-direction: Column;
  justify-content: center;
  align-items: center;
  width: 100%;
  h2 {
    margin-top: 64px;
    margin-bottom: 40px;
    text-align: center;
  }
`
export const FormWrap = styled.div`
  width: 90%;
  padding: 16px 0 40px 0;
  form {
    margin-bottom: 40px;
  }

  @media screen and (min-width: 769px) {
    width: 60%;
  }
`

export const Input = styled.div`
  /* Mobile First */
  display: flex;
  align-items: center;
  width: 100%;
  height: 3em;
  padding: 10px 0 10px 16px;
  border-radius: 4px;
  border: 2px solid #ededed;
  background: #ededed;
  & + div {
    margin-top: 12px;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #333;
    font-size: 12px;
    &::placeholder {
      color: #777;
    }
  }
  /* RESPONSIVE */
  @media screen and (min-width: 769px) {
    width: 100%;

    input {
      font-size: 0.92em;
    }
  }
`

export const Button = styled.button`
  padding: 0 16px;
  width: 240px;
  height: 48px;
  border-radius: 4px;
  border: 0;
  background-color: #00649f;
  color: #fafafa;
  text-transform: uppercase;
  font-size: 12px;

  transition: background-color 0.2s;
  :hover {
    opacity: 0.9;
  }
`
