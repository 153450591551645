import { graphql, useStaticQuery } from "gatsby"

export const useThanksMessageDataQuery = () => {
  const data = useStaticQuery(graphql`
    query ThanksMessage {
      mdx(frontmatter: { type: { eq: "thanks" } }) {
        id
        frontmatter {
          title
          sideImg {
            publicURL
          }
          description
        }
      }
    }
  `)
  return data.mdx.frontmatter
}
