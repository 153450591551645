import styled from "styled-components"
export const Container = styled.div``

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 16px;
  h2 {
    text-align: center;
    margin-bottom: 24px;
  }
  p {
    text-align: justify;
    font-size: 1.1em;
    line-height: 1.5em;
  }
`

export const ImageItem = styled.img`
  width: 280px;
  margin-bottom: 80px;
`
