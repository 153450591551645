import React from "react"
import Select from "react-select"
import { useForm } from "@formspree/react"

import {
  Button,
  Container,
  Content,
  FormWrap,
  Input,
} from "./ScheduleForms.styles"
import ThankYou from "../../ThankYou"

const period = [
  { value: "matutino", label: "Manhã" },
  { value: "vespertino", label: "Tarde" },
  { value: "noturno", label: "Noite" },
]

const weekDay = [
  { value: "segunda", label: "Segunda" },
  { value: "terça", label: "Terça" },
  { value: "quarta", label: "Quarta" },
  { value: "quinta", label: "Quinta" },
  { value: "sexta", label: "Sexta" },
]

const customStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: "#ededed",
    border: "none",
    padding: "10px 0 10px 16px",
    marginBottom: "12px",
    fontSize: "12px",
  }),
  option: styles => ({
    ...styles,
    fontSize: "12px",
  }),

  dropdownIndicator: () => ({
    color: "grey",
    padding: "0 12px",
  }),
}

const ScheduleForm = () => {
  const [state, handleSubmit] = useForm("mlezjwpr")
  if (state.succeeded) {
    return <ThankYou />
  }

  return (
    <Container>
      <Content>
        <h2>Formulário de agendamento</h2>
        <FormWrap>
          <form onSubmit={handleSubmit} name="schedule-form">
            <Input>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Nome Completo"
              />
            </Input>
            <Input>
              <input type="email" name="email" id="email" placeholder="Email" />
            </Input>
            <Input>
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="Telefone"
              />
            </Input>
            <Input>
              <input
                type="text"
                name="clinic"
                id="clinic"
                placeholder="Clinica"
              />
            </Input>
            <Input>
              <input type="text" name="exam" id="exam" placeholder="Exame" />
            </Input>
            <Select
              options={period}
              styles={customStyles}
              placeholder="Escolha um período..."
              name="period"
            />
            <Select
              options={weekDay}
              styles={customStyles}
              placeholder="Escolha um dia da semana..."
              name="weekday"
            />
            <Button type="submit" disabled={state.submitting}>
              Solicitar Agendamento
            </Button>
          </form>
        </FormWrap>
      </Content>
    </Container>
  )
}

export default ScheduleForm
