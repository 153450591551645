import React from "react"

import Seo from "components/seo"
import HeroSection from "components/HeroSection"
import ScheduleForm from "components/Forms/ScheduleForm"

import { useSchedulePageDataQuery } from "hooks/useSchedulePageDataQuery"

const Index = () => {
  const { heroContent } = useSchedulePageDataQuery()

  return (
    <>
      <Seo title="Serviços" />
      <HeroSection {...heroContent} />
      <ScheduleForm />
    </>
  )
}

export default Index
