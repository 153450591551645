import React from "react"
import { useThanksMessageDataQuery } from "hooks/useThanksMessageDataQuery.js"

import { Container, Content, ImageItem } from "./ThankYou.styles.js"
const ThankYou = () => {
  const { title, sideImg, description } = useThanksMessageDataQuery()
  return (
    <Container>
      <Content>
        <ImageItem
          src={sideImg.publicURL}
          alt="imagem ilustrando envio sucesso"
        />
        <h2>{title}</h2>
        <p>{description}</p>
      </Content>
    </Container>
  )
}

export default ThankYou
